
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      openAccordionIndex: 0,
    }
  },
}
