
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },

    bgWhite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: this.open,
    }
  },

  watch: {
    open(value) {
      this.selected = value
    },
  },

  methods: {
    toggleAccordion() {
      this.selected = !this.selected

      if (this.selected) {
        this.$emit('open')
      }
    },

    beforeEnter(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },

    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    afterEnter(el) {
      el.style.opacity = '1'
    },

    beforeLeave(el) {
      el.style.opacity = '1'
      el.style.height = el.scrollHeight + 'px'

      // Required to force recalculation. Without it first closing is not smooth.
      // eslint-disable-next-line
      el.scrollHeight
    },

    leave(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
  },
}
